/** @module global/request/restaurant */
import RequestUtility from './request';

const SERVICE_URL = 'https://d2if4tc6g5.execute-api.us-east-1.amazonaws.com/prod';
// window.location.hostname === 'www.getfoodfight.link'
//   ? 'https://d2if4tc6g5.execute-api.us-east-1.amazonaws.com/prod'
//   : 'https://v5j4xszs7i.execute-api.us-east-1.amazonaws.com/prod';
const util = new RequestUtility(SERVICE_URL);

/**
 * Transforms a restaurant object returned from the service into a Restaurant object
 * @param {Object} restaurant Restaurant object returned from service
 * @returns {types.Restaurant} Transformed restaurant
 */
const transformRestaurant = restaurant => ({
  id: restaurant.id,
  name: restaurant.name || 'Unset value',
  address: restaurant.address || 'Unset value',
  rating: restaurant.rating || 0,
  delivery: restaurant.delivery || false,
  dineIn: restaurant.dine_in || false,
  phoneNumber: restaurant.phone_number || 'Unset value',
  icon: restaurant.icon || null,
});

/**
 * Transforms an item object returned from the service into a MenuItem object
 * @param {Object} item Item object returned from service
 * @returns {types.MenuItem} Transformed item
 */
const transformItem = item => ({
  id: item.id,
  name: item.item_name,
  category: item.category,
  price: item.cost,
  restaurantId: item.restaurant_id,
});

/**
 * Sends a request to get all restaurants
 * @param {string}   The bearer token to use for authentication. This should be the id token obtained from authentication
 * @returns Array of restaurants
 */
async function getRestaurants(bearerToken) {
  const response = await util.authorizedRequest('restaurants/~', {
    method: 'GET',
    bearerToken,
  });
  return response.map(restaurant => transformRestaurant(restaurant));
}

/**
 * Sends a request to get a restaurant
 * @param {string} bearerToken The bearer token to use for authentication. This should be the id token obtained from authentication
 * @param {number} restaurantId ID of the restaurant to fetch
 * @returns The restaurant
 */
async function getRestaurant(bearerToken, restaurantId) {
  const response = await util.authorizedRequest(`restaurants/${restaurantId}`, {
    method: 'GET',
    bearerToken,
  });
  return transformRestaurant(response);
}

/**
 * Sends a request to get a restaurant's menu
 * @param {number} restaurantId ID of the restaurant to fetch
 * @param {string} bearerToken The bearer token to use for authentication. This should be the id token obtained from authentication
 * @returns Array of menu items
 */
async function getMenu(restaurantId, bearerToken) {
  try {
    const response = await util.authorizedRequest(`restaurants/menu/${restaurantId}`, {
      method: 'GET',
      bearerToken,
    });

    if (!response || Object.keys(response).length === 0) {
      console.warn('Recieved empty data from MealMe menu, using menu_items');
      const backup_response = await util.authorizedRequest(`menu_items/restaurants/${restaurantId}`, {
        method: 'GET',
        bearerToken,
      });
      console.log(backup_response);
      return backup_response.map(item => transformItem(item));
    }

    return response;
  } catch (error) {
    const response = await util.authorizedRequest(`menu_items/restaurants/${restaurantId}`, {
      method: 'GET',
      bearerToken,
    });
    return response.map(item => transformItem(item));
  }
}

const exports = {
  getRestaurant,
  getRestaurants,
  getMenu,
};

export { getRestaurant, getRestaurants, getMenu };
export default exports;
