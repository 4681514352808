import React, { useContext, useRef, useState, useEffect } from 'react';
// import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { IonButton, IonPage, IonContent, useIonRouter, IonLabel } from '@ionic/react';
// import { NativeBiometric, BiometryType } from 'capacitor-native-biometric';
import EntryField from './EntryField';
import '../../styles/authentication/SignIn.scss';
import logoText from '../../assets/tmp_logo_text.svg';
import logoIcon from '../../assets/tmp_logo_icon.svg';
import { AuthDispatchContext } from './AuthContext';
import { signin } from '../../global/request/auth';
import MessageOverlay from '../fight/MessageOverlay';
import usePushNotifcations from '../notifications/PushNotifications';
import { login as amityLogin } from '../../services/amityClient';
import { verifyLogin } from '../../global/request/user';

function SignIn() {
  const router = useIonRouter();
  const authDispatchCtx = useContext(AuthDispatchContext);
  const msgRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authErrMsg, setAuthErrMsg] = useState('');
  const [amityLoginStatus, setAmityLoginStatus] = useState(null);

  const handleSignIn = async () => {
    try {
      // Authenticate with our app
      const result = await signin({
        email,
        password,
      });

      console.log('signin result:', result);

      // userInfo
      const userId = result.idData.sub;
      const displayName = result.idData.email;

      console.log('userId:', userId);
      console.log('displayName:', displayName);

      authDispatchCtx.dispatch({
        action: 'storeAuthResult',
        authResult: result,
      });

      const isConnected = await amityLogin(userId, displayName);

      if (isConnected) {
        setAmityLoginStatus('Login to Amity successful');
        // if (Capacitor.isNativePlatform()) {
        //   NativeBiometric.setCredentials({
        //     username: result.idData.email,
        //     password: result.idData.password,
        //     server: 'getfoodfight.link',
        //   }).then();
        //   console.log('Credentials saved to Biometric storage.');
        // }
        await Preferences.set({ key: 'showVenueModal', value: 'true' });
        router.push('/app/home');
      } else {
        setAmityLoginStatus('Login to Amity failed');
      }

      try {
        await verifyLogin(result.tokens.idToken);
        console.log('Login verification successful');
      } catch (error) {
        console.error('Error verifying login:', error);
      }
    } catch (error) {
      setAuthErrMsg(error.message);
      msgRef.current.present();
      console.error('handleSignIn error:', error);
    }
  };

  // const checkBiometricAndRetrieveCredentials = async () => {
  //   try {
  //     const result = await NativeBiometric.isAvailable();

  //     if (!result.isAvailable) {
  //       console.log('Biometric authentication is not available.');
  //       return;
  //     }

  //     const isFaceID = result.biometryType === BiometryType.FACE_ID;
  //     console.log('Using Face ID:', isFaceID);

  //     const verified = await NativeBiometric.verifyIdentity({
  //       reason: 'For easy log in',
  //       title: 'Log in',
  //       subtitle: 'Authenticate to access your account',
  //       description: 'Use Face ID to quickly log in',
  //     })
  //       .then(() => true)
  //       .catch(() => false);

  //     if (!verified) {
  //       console.log('Face ID Authentication Failed.');
  //       return;
  //     }

  //     const credentials = await NativeBiometric.getCredentials({
  //       server: 'getfoodfight.link',
  //     });

  //     if (credentials) {
  //       setEmail(credentials.username);
  //       setPassword(credentials.password);
  //       // if (credentials.username && credentials.password) {
  //       //   handleSignIn(credentials.username, credentials.password);
  //       // }
  //     }
  //   } catch (error) {
  //     console.error('Error with Face ID authentication or retrieving credentials:', error);
  //   }
  // };

  useEffect(() => {
    if (amityLoginStatus) {
      console.log(amityLoginStatus);
    }
  }, [amityLoginStatus]);

  usePushNotifcations();

  return (
    <IonPage>
      <IonContent id="signin-page">
        <div id="signin-content">
          <div id="logo-imgs">
            <img id="logo-icon" src={logoIcon} alt="FoodFight Logo" />
            <img id="logo-text" src={logoText} alt="FoodFight Alphanumeric" />
          </div>
          <p className="challenge-text">Challenge your friends for the tab</p>
          <form id="entry-options" onSubmit={event => event.preventDefault()}>
            <IonLabel className="login-label">Account</IonLabel>
            <EntryField
              id="email"
              placeholder="johndoe@email.com"
              type="email"
              setter={setEmail}
              value={email}
              // onClick={checkBiometricAndRetrieveCredentials}
            />
            <IonLabel className="login-label">Password</IonLabel>
            <EntryField
              id="password"
              placeholder="***********"
              type="password"
              setter={setPassword}
              value={password}
              // onClick={checkBiometricAndRetrieveCredentials}
            />
            <div className="forgot-password" role="button" tabIndex={0} onClick={() => router.push('/reset-password')}>
              Forgot Password?
            </div>
            <IonButton id="signin-btn" expand="block" onClick={handleSignIn}>
              Sign In
            </IonButton>
            <IonButton id="signup-btn" expand="inline-block" fill="outline" onClick={() => router.push('/signup')}>
              Create an Account
            </IonButton>
          </form>
        </div>
        <MessageOverlay blur autoExit lifespan={2.5} msg={authErrMsg} ref={msgRef} />
      </IonContent>
    </IonPage>
  );
}

export default SignIn;
